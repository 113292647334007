.t {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  position: fixed;
  top: 27.5px;
  right: 50px;
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #294d90;
}

.t-icon {
  width: 20px;
  height: 20px;
}

.t-button {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: yellow;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  transition: all 0.2s ease;
}

@media screen and (max-width: 420px) {
  .t {
    right: 20px;
  }
}
