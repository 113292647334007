.gdpr {
  padding: 8rem 15%;
}

.gdpr a {
  margin: 0 10px;
}

.gdpr p {
  font-size: 18px;
}

.gdpr li {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

.gdpr h1 {
  font-size: 60px;
  text-align: center;
  color: #222;
}

.gdpr h2 {
  background-color: #294d90;
  font-size: 28px;
  padding: 10px;
  margin: 40px auto 0;
  max-width: 1000px;
  text-align: center;
  color: #fff;
  margin-bottom: 25px;
}

.gdpr figure img {
  display: block;
  width: 200px;
  text-align: center;
  margin: auto;
}

.gdpr h3 {
  font-size: 22px;
}

.gdpr ul {
  list-style: none;
  padding-inline-start: 0;
}

.gdpr a {
  color: #294d90;
  text-decoration: none;
}

.gdpr a:hover {
  text-decoration: underline;
}

/* TABLE  */

.gdpr .table {
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.gdpr .table ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  list-style: none;
  margin: auto;
}

.gdpr .table li {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  font-weight: 200;
}

.gdpr .table li p {
  padding: 0 1em;
  width: 15%;
  text-align: left;
  text-decoration: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
}

.gdpr strong {
  margin-right: 10px;
}

.gdpr p b {
  margin-right: 10px;
}

.gdpr .table li p:nth-child(3) {
  width: 40%;
}

.gdpr .table li:nth-child(odd):not(.table-header) {
  background: #294d90;
  color: #fff;
}

.gdpr .table li:hover:not(.table-header) {
  background: #000;
  color: #ccc;
}

.gdpr .table-header {
  background: #000;
  color: #fff;
}

.table-header p {
  font-weight: bold;
}

/* TABLE  */
@media (max-width: 1024px) {
  .gdpr {
    padding: 8rem 50px;
  }
}

@media (max-width: 768px) {
  .gdpr h1 {
    font-size: 40px;
  }

  .gdpr h2 {
    font-size: 22px;
  }

  .gdpr h3 {
    font-size: 20px;
  }

  .gdpr figure img {
    width: 120px;
    margin-top: 0;
  }

  .gdpr p,
  .gdpr li {
    font-size: 16px;
  }

  .gdpr .table li {
    width: 100%;
  }

  .gdpr .table-header {
    font-size: 11px;
  }

  .gdpr .table li p {
    font-size: 10px;
  }
}

@media (max-width: 420px) {
  .gdpr {
    padding: 8rem 20px;
  }
  .gdpr .table li p {
    font-size: 8px;
  }
}

@media (max-width: 320px) {
  .gdpr {
    padding-left: 0;
    padding-right: 0;
  }
  .gdpr .table li p {
    font-size: 7px;
  }
}
