.cookies {
  display: flex;
  flex-direction: column;
  padding: 7rem 15% 2rem;
}

.cookies a {
  margin: 0 10px;
}

.cookies h3,
.link {
  text-align: center;
}

.cookies h3 {
  text-decoration: underline;
}

.cookies p {
  font-size: 18px;
}

.cookies figure {
  text-align: center;
}

.cookies li {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

.cookies h1 {
  font-size: 60px;
  text-align: center;
  margin-top: 1rem;
  font-weight: 700;
}

.cookies h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #294d90;
  padding: 10px;
  margin: 2rem auto;
  width: 80%;
}

.cookies ul {
  list-style: none;
  padding-inline-start: 0;
}

.cookies a {
  color: #294d90;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .cookies {
    padding: 7rem 50px 4rem;
  }
}

@media (max-width: 768px) {
  .cookies {
    padding: 7rem 20px 2rem;
  }
  .cookies h1 {
    font-size: 28px;
  }

  .cookies h2 {
    font-size: 20px;
    margin: 20px 0;
    display: flex;
    align-self: center;
  }

  .cookies p {
    font-size: 16px;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .cookies h2 {
    font-size: 18px;
    width: 100%;
  }
}
