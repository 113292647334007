.impressum .bg-gearbox {
  background-color: #294d90;
  color: #fff;
  font-size: 28px;
  padding: 10px 20px;
  width: fit-content;
  margin: auto;
  margin-top: 100px;
}

.impressum a {
  margin: 0 10px;
  color: #294d90;
}

.impressum {
  padding: 7rem 0;
}

.impressum img {
  margin-top: 1rem;
}

.impressum li {
  text-align: center;
  padding: 10px;
  font-size: 18px;
}

.impressum b {
  display: block;
}

.impressum h1 {
  font-size: 60px;
  text-align: center;
  margin-top: 40px;
}

.impressum ul {
  list-style: none;
  padding-inline-start: 0;
}

@media (max-width: 768px) {
  .impressum h1 {
    font-size: 40px;
  }
}
