.i {
  display: flex;
  height: 100vh;
}

.i-left {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-right {
  flex: 3;
  position: relative;
}

.i-left-wrapper {
  padding: 50px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.i-intro {
  font-size: 40px;
  font-weight: 300;
}

.i-name {
  font-size: 70px;
  line-height: 1;
}

.i-title {
  height: 60px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  color: #2a4f8f;
  animation: scrolling-text 10s ease-in-out infinite alternate;
}

@keyframes scrolling-text {
  25% {
    transform: translateY(-60px);
  }
  50% {
    transform: translateY(-120px);
  }
  75% {
    transform: translateY(-180px);
  }
  100% {
    transform: translateY(-240px);
  }
}

.i-title-item {
  height: 60px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.i-img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
}

.i-bg {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  width: 100%;
  height: calc(100% - 80px);
  background-color: #2a4f8f;
  position: absolute;
  top: 80px;
  right: 0;
}

.i-scroll {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 20px;
  display: flex;
  align-self: center;
}

@media screen and (max-width: 1024px) {
  .i {
    flex-direction: column;
  }
  .i-left-wrapper {
    padding: 100px 50px 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
    font-size: 22px;
  }

  .i-title {
    height: 60px;
  }

  .i-title-item {
    height: 60px;
    font-size: clamp(1.5rem, 1.5rem + 0.5vw, 3rem);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes scrolling-text {
    25% {
      transform: translateY(-60px);
    }
    50% {
      transform: translateY(-120px);
    }
    75% {
      transform: translateY(-180px);
    }
    100% {
      transform: translateY(-240px);
    }
  }

  .i-description {
    padding-top: 10px;
  }

  .i-description p {
    font-size: 22px;
  }

  .i-name {
    font-size: 40px;
    margin: 15px 0;
  }

  .i-img {
    object-fit: scale-down;
  }
}

@media screen and (max-width: 420px) {
  .i-scroll {
    display: none;
  }

  .i-left-wrapper {
    padding: 100px 10px 10px;
  }
  .i-description p {
    font-size: 18px;
  }
  .i-title {
    height: 40px;
  }
  .i-title-item {
    height: 40px;
    font-size: 24px;
  }
  .i-bg {
    clip-path: polygon(50% 0, 100% 25%, 100% 100%, 0 100%, 0 25%);
  }
  @keyframes scrolling-text {
    25% {
      transform: translateY(-40px);
    }
    50% {
      transform: translateY(-80px);
    }
    75% {
      transform: translateY(-120px);
    }
    100% {
      transform: translateY(-160px);
    }
  }
}
