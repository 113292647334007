.n {
  display: flex;
  position: fixed;
  flex-direction: row;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 80px;
  z-index: 100;
  padding: 0 120px 0 50px;
  align-items: center;
  justify-content: space-around;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
}

.logo {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
}

.logo-img {
  width: 60px;
  height: 60px;
}

.menu {
  font-size: 18px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 1.2rem;
}

li {
  list-style-type: none;
}

li a {
  text-decoration: none;
  color: #222;
  text-transform: uppercase;
  font-weight: 300;
}

@media screen and (max-width: 1024px) {
  .n {
    display: none;
  }
  .header {
    display: flex;
    position: fixed;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    z-index: 100;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
    background-color: #fff;
  }
  .hamburger-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 22px;
    height: 25px;
    cursor: pointer;
  }

  .hamburger-1,
  .hamburger-2,
  .hamburger-3 {
    width: 20px;
    height: 3px;
    background-color: black;
    transition: all 0.3s ease-in-out;
  }

  .close-1 {
    transform: rotate(45deg) translateY(6px);
  }
  .close-3 {
    transform: rotate(-45deg) translateY(-6px);
  }
  .close-2 {
    display: none;
  }

  .mobile-menu {
    position: fixed;
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    align-items: center;
    justify-content: space-evenly;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    padding: 2rem 5%;
    list-style: none;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 99999;
    background-color: #fefefe;
  }

  .mobile-active {
    transform: translateX(0);
  }
}
