.gallery {
  margin: 0 0 200px;
  padding-top: 80px;
}

.gallery h2 {
  font-size: 50px;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .gallery {
    margin: 50px 0;
    padding-top: 0;
  }

  .gallery h2 {
    font-size: 40px;
  }
}
