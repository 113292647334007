.card-wrapper {
  display: flex;
  flex-direction: column;
  width: 32%;
  align-items: stretch;
}

.title-wrapper {
  position: relative;
  width: 100%;
  font-size: 0.8rem;
  height: 7vh;
  margin: 50px 0;
}

.title-wrapper::after {
  position: absolute;
  content: '';
  bottom: -10px;
  left: 0;
  width: 50%;
  transform: translate(50%, 0);
  height: 4px;
  background-color: #2a4f8f;
}

.p {
  height: 50vh;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  position: relative;
}

.p-browser {
  height: 20px;
  background-color: #333;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 3px;
}

.p-circle.c1 {
  background-color: rgb(255, 30, 0);
  margin-left: 10px;
}

.p-circle.c2 {
  background-color: rgb(253, 175, 29);
}

.p-circle.c3 {
  background-color: rgb(145, 219, 33);
}

.p-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
}

.p-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 10px;
}

.p:hover .p-img {
  transform: translateY(-100%);
}

@media screen and (max-width: 1024px) {
  .card-wrapper {
    width: 45%;
  }

  .title-wrapper {
    margin-bottom: 20px;
    height: 5vh;
  }
  .p {
    margin: 0;
    height: 30vh;
  }
}

@media screen and (max-width: 768px) {
  .title-wrapper {
    height: 9vh;
  }
  .p-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .card-wrapper,
  .title-wrapper {
    width: 100%;
  }

  .title-wrapper {
    margin-top: 10vh;
    height: 9vh;
  }

  .title-wrapper h2 {
    font-size: 20px;
  }

  .p {
    border-radius: 0;
    height: 50vh;
  }

  .p-browser {
    display: none;
  }
  .p-text {
    font-size: 14px;
  }
}
