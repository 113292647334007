.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem 10% 0;
}

.a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  padding: 10rem 0 5rem;
}

.a-left {
  /* flex: 1; */
  display: flex;
  align-items: center;
  width: 45%;
}

.a-right {
  /* flex: 1; */
  width: 45%;
}

.images-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5rem;
}

.side-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
}

.img-card {
  width: 100%;
  height: 100%;
}

.a-card {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-bottom h3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  background-color: #294d90;
  color: #fff;
  padding: 2rem 1rem;
}

.a-award-img {
  width: 180px;
  height: 180px;
  padding-right: 10px;
  border-radius: 30px;
}

p {
  font-size: 18px;
  margin-bottom: 16px;
}

.a-title {
  font-weight: 600;
  font-size: 50px;
}

.a-sub {
  margin: 20px 0;
}

.a-desc {
  font-weight: 300;
}

.a-desc span {
  font-weight: 700;
  color: #294d90;
}

.a-award {
  background-color: #294d90;
  color: #fff;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 30px;
  text-decoration: none;
}

.a-award:hover {
  cursor: pointer;
}

.a-award-desc {
  text-align: center;
}

.a-award-texts {
  width: 70%;
}

.a-award-title {
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.cta-call {
  margin: auto;
  margin-top: 3rem;
}

.btn {
  width: 100%;
  border: 2px solid #2a4f8f;
  padding: 15px 30px;
  background-color: #2a4f8f;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 22px;
  text-decoration: none;
}

.btn:hover {
  border: 2px solid #2a4f8f;
  background-color: transparent;
  color: #2a4f8f;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 0;
  }

  .images-wrapper {
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 3rem;
  }

  .a {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
    height: 100%;
    width: 100%;
  }

  /* .a-right {
    padding: 25% 10vw 0;
  } */

  .a-right p {
    font-size: 22px;
  }

  .a-left {
    width: 100%;
    order: 2;
  }
  .a-right {
    width: 100%;
  }

  .side-container {
    align-items: center;
    width: 100%;
  }

  .a-card {
    width: 100%;
    height: 100%;
    overflow: visible;
    order: 2;
  }

  p.a-sub,
  p.a-desc {
    margin: 5% 0 5%;
  }
}

@media screen and (max-width: 480px) {
  .a {
    margin: 50px 0;
    height: 100%;
    padding: 0;
  }

  .a-card.bg,
  .a-award {
    display: none;
  }

  .a-right {
    padding: 10px;
  }

  .a-left {
    max-height: 100%;
  }

  .a-right p {
    font-size: 18px;
  }

  p.a-sub,
  p.a-desc {
    padding: 0;
  }
}
