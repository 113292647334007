.c {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15vw;
}

.c-bg {
  width: 20px;
  height: 100%;
  background: #2a4f8f;
  position: absolute;
  left: 0;
  z-index: 220;
}

.c-wrapper {
  display: flex;
  padding: 50px 0;
}

.c-left {
  flex: 1;
}

.c-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.c-title {
  font-size: 60px;
  width: 80%;
}

.c-info-item {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 18px;
  width: 70%;
  margin: 20px 0;
}

.c-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.c-desc {
  font-weight: 200;
}

form {
  margin-top: 20px;
}

input {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #000;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
}

.check {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 20px 0;
}

.chbox {
  width: 20px;
  height: 20px;
  flex: 1;
}

.gdpr-warning {
  flex: 12;
  font-size: 14px;
}

button {
  width: 100%;
  border: 2px solid #2a4f8f;
  padding: 15px 30px;
  background-color: #2a4f8f;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 22px;
}

button:hover {
  border: 2px solid #2a4f8f;
  background-color: transparent;
  color: #2a4f8f;
}

.mail-sent {
  background-color: greenyellow;
  color: #222;
  font-size: 22px;
  border: 1px solid #222;
  width: fit-content;
  height: 54px;
  padding: 10px;
  margin: auto;
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .c {
    padding: 0 50px;
  }

  .c-wrapper {
    flex-direction: column;
  }

  .c-bg {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .c-right button {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 480px) {
  .c {
    height: 100%;
    padding: 0 20px;
  }

  .c-title {
    font-size: 40px;
    margin: 15px 0;
    width: 100%;
    text-align: center;
  }

  .c-info-item {
    width: 100%;
  }
}
