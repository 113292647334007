.f {
  width: 100%;
  display: flex;
  background-color: #222;
  flex-direction: column;
  position: relative;
}

.f-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 150px;
}

.f-block-left {
  flex: 1;
}

.f-block-center {
  flex: 4;
  align-items: center;
  justify-content: center;
}

.f-block-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.f-list {
  margin-bottom: 1rem;
}

.f-list ul {
  display: flex;
  width: 100%;
  flex-direction: row;
  font-size: 18px;
  justify-content: center;
}

.f-list a {
  color: #fff;
  padding: 0 20px;
}

.f-social {
  /* margin-top: 2rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  text-align: center;
  padding: 30px 0;
}

.f-social img {
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in-out;
}

.f-social img:hover {
  transform: rotate(45deg);
  cursor: pointer;
}

.face-wrapper {
  text-decoration: none;
  color: #fff;
}

.face-wrapper p {
  font-size: 12px;
}

.footer-logo-img img {
  width: 50%;
  height: 50%;
}

.f-menu {
  margin-top: -1rem;
}

.f-menu a {
  font-weight: 100;
}

.f-menu a,
h6 {
  color: #fff;
}

.f-list h6 {
  text-align: center;
  padding: 0 0 10px;
}

.f-menu h6,
.f-list h6 {
  padding-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
}

.f-menu a:hover,
.f-list a:hover {
  text-decoration: underline;
  text-shadow: 0 0 1px #fff;
}

.f-bottom {
  width: 100%;
  margin-bottom: 1rem;
}

.f-scroll {
  height: fit-content;
  width: 100%;
  overflow: hidden;
  text-transform: uppercase;
  color: #fff;
  padding: 20px;
}

.f-scroll-item {
  font-size: clamp(16px, 3vw, 40px);
  transform: translateX(100%);
  animation: scroll-item-left 20s linear infinite;

  text-align: center;
}

@keyframes scroll-item-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 1024px) {
  .f-top {
    padding: 50px;
  }
}

@media screen and (max-width: 768px) {
  .f-top {
    flex-direction: column;
    padding: 20px;
    text-align: center;
    align-items: center;
  }

  .f-menu li {
    padding: 5px 0;
  }

  .f-block-left {
    text-align: center;
  }

  .footer-logo-img img {
    width: 30%;
    height: 30%;
  }

  .f-block-left,
  .f-block-center,
  .f-block-right {
    padding-top: 0;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 420px) {
  .f-list ul {
    font-size: 12px;
  }

  .f-list a {
    color: #fff;
    padding: 0 10px;
  }
}
