.mapouter {
  position: relative;
  text-align: right;
  height: 600px;
  width: 100%;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 600px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .mapouter {
    height: 450px;
  }
  .gmap_canvas {
    height: 450px;
  }
}
