.pl {
  padding: 150px 10% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.pl h1 {
  line-height: 1;
}

.pl-texts {
  width: 85%;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
}

.pl-desc {
  margin: 20px 0;
  font-size: 22px;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.m {
  width: 100%;
}

.m-title h2 {
  text-transform: uppercase;
  margin-top: 80px;
  font-size: 24px;
}

.m-img {
  max-width: 200px;
  margin: auto;
}

.m-img img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .pl {
    padding: 10% 10px 40px;
  }

  .pl-desc {
    font-size: 20px;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .pl {
    padding: 10% 10px 40px;
  }
  .pl-texts {
    width: 80%;
    margin-top: 5rem;
  }
  .pl-desc {
    font-size: 18px;
  }
  .m-title h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .pl {
    padding: 5% 10px 15%;
  }
  .pl-texts {
    width: 90%;
  }
  .pl-title {
    font-size: 40px;
  }

  .pl-list {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .pl-desc {
    font-size: 16px;
  }
  .m-title h2 {
    font-size: 16px;
  }
}
